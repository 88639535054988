html,
body {
  margin: 0% !important;
  padding: 0% !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

* {
  box-sizing: border-box;
}

.MuiFormHelperText-root {
  color: red !important;
  margin: 0% !important;
}

.main_container {
  padding: 0% !important;
}

/*======================
    404 page
=======================*/

.error_container {
  padding-top: 3rem;
}

.error_message {
  color: red;
  font-weight: bold !important;
}

.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  border: none !important;
  height: 1em !important;
}

.div_not_found {
  border-radius: 20px;
  margin: 1rem;
  padding: 1rem;
  -webkit-box-shadow: 0px 3px 20px 0px rgba(128, 128, 128, 1);
  -moz-box-shadow: 0px 3px 20px 0px rgba(128, 128, 128, 1);
  box-shadow: 0px 3px 20px 0px rgba(128, 128, 128, 1);
}

.not_found_image {
  max-width: 100%;
  max-height: 70vh;
  padding: 0;
  display: block;
  justify-content: center;
  align-self: center;
  margin: auto;
}

.white_nav {
  background-color: white !important;
  color: gray !important;
}

.plain_nav {
  background-color: white !important;
  box-shadow: none !important;
  color: gray !important;
  padding: 0rem 0.5rem !important;
}

.cmain {
  animation: fadeInAnimation ease 0.5s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.link:hover {
  cursor: pointer;
  text-decoration: underline;
  color: #fec310;
}

.link {
  cursor: pointer;
  text-decoration: underline;
}

.otp_input_custom {
  display: flex;
  gap: 20px;
  max-width: 16rem;
  margin-inline: auto;
  border: none !important;
  margin-bottom: 1rem;
}

.otp_input_custom input {
  background-color: #f9f9f9;
  -webkit-box-shadow: 0px 3px 5px -3px rgba(0, 0, 0, 0.64);
  -moz-box-shadow: 0px 3px 5px -3px rgba(0, 0, 0, 0.64);
  box-shadow: 0px 3px 5px -3px rgba(0, 0, 0, 0.64);
  border-radius: 29%;
}

.otp_input_custom fieldset {
  border: none !important;
}

.otp_input_custom_long {
  display: flex;
  gap: 20px;
  max-width: 25rem;
  margin-inline: auto;
  border: none !important;
  margin-bottom: 1rem;
}

.otp_input_custom_long .MuiOutlinedInput-input {
  height: 1rem !important;
}

.otp_input_custom_long input {
  background-color: #f9f9f9;
  -webkit-box-shadow: 0px 3px 5px -3px rgba(0, 0, 0, 0.64);
  -moz-box-shadow: 0px 3px 5px -3px rgba(0, 0, 0, 0.64);
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.64);
  border-radius: 29%;
}

.otp_input_custom_long fieldset {
  border: none !important;
}

.icon_color {
  color: #fec310;
}

.font_color {
  color: #636363;
}

.asterik::after {
  content: '*';
  color: red;
  margin: 2px;
}

.line_div {
  width: 80%;
  text-align: center;
  border-bottom: 1px solid #636363;
  line-height: 0.1em;
  margin: 3rem auto;
  color: #636363;
}

.line_div span {
  background: #fff;
  padding: 0 10px;
}

.pin_input_custom input {
  color: #383454 !important;
  font-size: x-large;
  padding: 0.5rem !important;
}

.pin_input_custom {
  display: flex;
  gap: 20px;
  max-width: 16rem;
  margin-inline: auto !important;
}

.pin_input_custom fieldset {
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-radius: 0% !important;
  border-bottom: 2px solid #636363 !important;
}

.stepper_container {
  max-width: 500px;
  margin: 0 auto;
}

.avatar {
  border-radius: 50%;
}

.flash {
  animation: fadeInOut 1.2s ease-in-out infinite;
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}

.dashboard_card {
  position: relative;
  overflow: hidden;
  transition: transform 0.3s;
}

.dashboard_card:hover {
  transform: scale(1.02);
  transition: transform 0.2s ease-in-out;
  cursor: auto;
}

.dashboard_card::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 0;
  height: 0;
  background-color: rgba(207, 206, 206, 0.2);
  border-radius: 50%;
  transition: all 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  pointer-events: none;
}

.dashboard_card:hover::before {
  width: 200%;
  height: 200%;
  transform: translate(-50%, -50%);
  opacity: 0;
}

.carousel {
  border-radius: 20px !important;
  height: max-content;
}

.custom-dialog .MuiDialog-paper {
  border-radius: 15px;
}

.closeButton {
  position: absolute;
  right: 0%;
  margin-top: 0.5rem !important;
}

.MuiDialogContentRootc {
  padding-top: 0;
  overflow-y: initial !important;
}

.custom_scrollable_container {
  max-height: 600px;
  overflow-y: auto;
  scrollbar-width: thin !important;
  scrollbar-color: #888888 #f0f0f0;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #888888;
  }

  &::-webkit-scrollbar-track {
    background-color: #f0f0f0;
  }
}

.icon_contact {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: #fec310;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  padding: 5px;
}

.alink {
  color: #383454 !important;
}

@media screen and (max-width: 424px) {
  .otp_input_custom_long > div > div > input {
    padding: 10px !important;
  }

  .otp_input_custom_long {
    gap: 10px !important;
  }

  .otp_input_custom_long .MuiOutlinedInput-input {
    height: 0.9rem !important;
  }
}

.imgbox {
  border-radius: 10%;
  cursor: pointer;
}

textarea {
  padding: 10px !important;
}

@media screen and (max-width: 768px) {
  .container_css {
    /* overflow: auto; */
    margin-bottom: 15vh;
  }
}

.custom_menu {
  min-height: 20px !important;
  padding-top: 1px !important;
  padding-bottom: 1px !important;
  font-size: 14px !important;
  margin-top: 3px !important;
  margin-bottom: 3px !important;
  font-weight: 500 !important;
}

.react-crop {
  cursor: move !important;
}

.react-crop .ReactCrop__drag-handle {
  cursor: move !important;
}

.react-crop .ReactCrop__drag-handle--n {
  cursor: n-resize !important;
}

.react-crop .ReactCrop__drag-handle--s {
  cursor: s-resize !important;
}

.react-crop .ReactCrop__drag-handle--e {
  cursor: e-resize !important;
}

.react-crop .ReactCrop__drag-handle--w {
  cursor: w-resize !important;
}

.react-crop .ReactCrop__drag-handle--ne {
  cursor: ne-resize !important;
}

.react-crop .ReactCrop__drag-handle--se {
  cursor: se-resize !important;
}

.react-crop .ReactCrop__drag-handle--sw {
  cursor: sw-resize !important;
}

.react-crop .ReactCrop__drag-handle--nw {
  cursor: nw-resize !important;
}

.ReactCrop__drag-handle {
  background-color: #fec310 !important;
}

.ReactCrop__drag-handle::after {
  border: 1px solid #fec310 !important;
}

.ReactCrop {
  display: flex !important;
  padding: 10px !important;
}

@media (max-width: 600px) {
  .bottom-navigation {
    /* width: 100%; */
    padding: 1rem !important;
    overflow-x: auto;
  }

  .bottom-navigation .MuiBottomNavigationAction-root {
    min-width: 66px !important;
  }
}

.accordian::before {
  background-color: transparent !important;
}

.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.responsive-button {
  flex: 1;
  white-space: nowrap;
  text-overflow: ellipsis !important;
  min-width: 0;
  font-size: 1rem;
  padding: 8px !important;
}

@media (min-width: 768px) {
  .responsive-button {
    font-size: 1.5rem;
  }
}

.MuiCardContent-root:last-child {
  padding-bottom: 15px !important;
}

.sublinks-container {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  gap: 10px;
  opacity: 0;
  pointer-events: none;
  transition:
    opacity 0.3s,
    transform 0.3s;
  transform-origin: center top;
}

.sublinks-container {
  opacity: 1;
  pointer-events: auto;
  transform: translateX(-85%) translateY(40px);
}

.sublink {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  background-color: #fec310;
  color: white;
  border-radius: 50%;
  text-decoration: none;
  transform: scaleY(0);
  transform-origin: center top;
  transition: transform 0.3s;
  transform: scaleY(1);
  font-size: '1rem';
}

@media screen and (min-width: 769px) {
  .mobileOnly {
    display: none;
  }

  .sublinks-container {
    transform: translateX(-51%) translateY(40px);
  }
}

@keyframes flicker {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

.flicker-animation {
  animation: flicker 1s infinite;
}

::-webkit-scrollbar {
  width: 10px;
  cursor: pointer;
}

::-webkit-scrollbar-thumb {
  background-color: #bdbdbd;
  border-radius: 10px;
  border: 2px solid #fff;
  cursor: pointer;
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  border-radius: 15px;
  cursor: pointer;
}

::-webkit-scrollbar-corner {
  background-color: transparent;
  cursor: pointer;
}

.MuiTableCell-stickyHeader {
  background-color: #fec310 !important;
}

@keyframes glowing {
  0% {
    box-shadow: 0 0 -8px #fec310;
  }
  40% {
    box-shadow: 0 0 15px #fec310;
  }
  60% {
    box-shadow: 0 0 15px #fec310;
  }
  100% {
    box-shadow: 0 0 -8px #fec310;
  }
}
.button-glow {
  animation: glowing 1500ms infinite;
}
